import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css' // 注意要引入css样式文件



Vue.use(Router)

/* Layout */
export const constantRoutes = [
  {
    path: '/',
    component: resolve => (require(['@/views/index'], resolve)),
    redirect: '/campaign',
  },
  {
    path: '/404',
    component: resolve => (require(['@/views/404'], resolve)),
    hidden: true
  },
  {
    path: '/campaign',
    name: 'campaign',
    component: resolve => (require(['@/views/campaign/index'], resolve)),
    meta: { title: '广告系列',keepAlive: true}
  },
  {
    path: '/adset',
    name: 'adset',
    component: resolve => (require(['@/views/adset/index'], resolve)),
    meta: { title: '广告组',keepAlive: false}
  },
  {
    path: '/ads',
    name: 'ads',
    component: resolve => (require(['@/views/ads/index'], resolve)),
    meta: { title: '广告',keepAlive: false}
  },
  {
    path: '/creatSyncJob',
    name: 'creatSyncJob',
    component: resolve => (require(['@/views/creatSyncJob/index'], resolve)),
    meta: { title: '同步数据'}
  },
  {
    path: '/syncResult',
    name: 'syncResult',
    component: resolve => (require(['@/views/creatSyncJob/syncResult'], resolve)),
    meta: { title: '同步结果'}
  },
  {
    path: '/productReport',
    name: 'productReport',
    component: resolve => (require(['@/views/productReport/index'], resolve)),
    meta: { title: '经营分析'}
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  // base:process.env.VUE_APP_ENV === 'production' ? '/admin/' : '/',
  mode: 'history', // require service support
  scrollBehavior: () => ({}),
  routes: constantRoutes
})

const router = createRouter()
router.beforeEach((to, from, next) => {
  NProgress.start() // 进度条开始
  next()
})

router.afterEach(() => {
  NProgress.done() // 进度条结束
})

// 进度条的配置项：ease可以设置css3动画，如ease，linear；speed是进度条从开始到结束的耗时
NProgress.configure({ease:'linear',speed: 500});
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
