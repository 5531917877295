import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import 'amfe-flexible'
// import '@/utils/flexable'
Vue.config.productionTip = false;
import './styles/index.less'; //公共样式
import './styles/common/commonly.scss';

import vueConfig from '../vue.config';
import './permission'; // 权限控制
import './utils/loading';
// 加水印
import '@/utils/watermark.js';
import {
  Button,
  NavBar,
  Picker,
  Field,
  Popup,
  Icon,
  Grid,
  GridItem,
  Cell,
  CellGroup,
  Empty,
  Form,
  Tab,
  Tabs,
  Search,
  Tag,
  Divider,
  RadioGroup,
  Radio,
  Switch,
  Dialog,
  Stepper,
  Toast,
  DropdownMenu,
  DropdownItem,
  ActionSheet,
  Calendar,
  Checkbox,
  CheckboxGroup,
  Row,
  Col,
  Collapse,
  CollapseItem,
  Image as VanImage,
  List,
  PullRefresh,
  SwipeCell,
  DatetimePicker
} from 'vant';

Vue.use(Button).use(NavBar).use(SwipeCell).use(Picker).use(Field).use(Popup).use(Icon).use(Grid).use(GridItem).use(Cell).use(CellGroup).use(Empty).use(Form).use(Tab).use(Tabs).use(Search).use(Tag).use(Divider).use(RadioGroup).use(Radio).use(Switch).use(Dialog).use(Stepper).use(Toast).use(DropdownMenu).use(DropdownItem).use(ActionSheet).use(Calendar).use(Checkbox).use(CheckboxGroup).use(Row).use(Col).use(Collapse).use(CollapseItem).use(VanImage).use(DatetimePicker);
Vue.use(CollapseItem).use(List).use(PullRefresh);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
