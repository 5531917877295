import service from '@/utils/request'
import {GET,POST} from '@/utils/request'
export function profile(data) {
    return GET('/login/profile')
}
// 钉钉免登录
export function dingAuthCode(code,userId) {
    let url = `/login/dingAuthCode?authCode=${code}`
    url = (userId || userId === 0) ? `${url}&userId=${userId}` : url;
    return GET(url)
}
// 获取凭证列表
export function credentailList(data) {
    return GET('/credentail/user/list')
}
// 网页登陆
export function check(data) {
    return GET('/login/check',data)
}