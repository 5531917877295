import axios from 'axios';

import Cookies from 'js-cookie';
import { Notify, Dialog } from 'vant';
const baseUrl = process.env.VUE_APP_BASE_API;
let userToken = Cookies.get('userToken');
if (userToken && typeof userToken == 'string') {
  userToken = JSON.parse(userToken);
} else {
  userToken = null;
}
let alertTime = '';
export function f_login() {
  // 阻止重复弹出,记录上次弹框时间
  let curTime = new Date().getTime();
  //   console.log(alertTime, curTime);
  if (alertTime && curTime - alertTime < 6000) {
    return false;
  } else {
    alertTime = curTime;
  }
  Dialog.confirm({
    title: '重新登陆',
    message: '确认重新登录吗',
  })
    .then(() => {
      let left = (window.screen.width - 500) / 2,
        top = (window.screen.height - 500) / 2;
      window.open('/api/login/relogin', '', `width=500,height=500,left=${left},top=${top}`);
    })
    .catch(() => {
      Notify({
        type: 'info',
        message: '已取消重新登录！',
      });
    });
}
// console.log(process.env);
const service = axios.create({
  baseURL: baseUrl, //
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    token: JSON.parse(localStorage.getItem('userInfo'))?.token || '',
  },
  transformRequest: [
    function (data) {
      return data;
    },
  ],
});

// 请求拦截器
service.interceptors.request.use(reqInt, (error) => {
  console.log(error); // for debug
  return Promise.reject(error);
});
async function reqInt(config) {
  // console.log(config);
  if (!userToken) {
    const _token = getQuery('_token');
    const _user = getQuery('_user');
    if (!localStorage.getItem('_token')) localStorage.setItem('_token', _token);
    if (!localStorage.getItem('_user')) localStorage.setItem('_user', _user);
    if (_token) {
      Cookies.set('userToken', JSON.stringify({ _token, _user }), { expires: 1 });
    } else {
      // 临时注释
      // f_login()
    }
    userToken = { _token, _user };
  }
  if (!config.params) {
    config.params = {};
  }
  config.params = Object.assign({}, config.params);
  return config;
}
let timer;
// let config;
// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    console.log('config', response);
    // 对响应数据做点什么
    const res = response.data;
    // console.log(response.config)
    // config = response.config;
    if (res.code != 0 && res.code != 401 && res.code !== 11) {
      Notify({ type: 'danger', message: res.comment || 'Error' });
      return Promise.reject(res);
    }
    return response;
  },
  function (err) {
    if (err.toString().includes('401')) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        // let confirm = window.confirm(`单点登陆过期，或者没有权限；\n如果有权限请点击"确定"刷新页面`);
        // if (confirm) {
        //     location.reload()
        // }
      }, 500);
      return;
    }
    // 对响应错误做点什么
    Notify({ type: 'danger', message: err.message.includes('timeout') ? '请求超时' : err.message });
    return Promise.reject(err);
  },
);

function getQuery(key) {
  let search = window.location.search.substr(1);
  if (!search) return null;
  let list = search.split('&');
  let obj = {};
  list.forEach((v) => {
    let query = v.split('=');
    obj[query[0]] = query[1];
  });
  return obj[key] || null;
}

export const GET = (url, params, baseURL, headers) => {
  return service({
    baseURL: baseURL,
    url: url,
    method: 'get',
    params,
    headers: headers,
  }).then((res) => res.data);
};
export const POST = (url, data, baseURL, headers) => {
  return service({
    baseURL: baseURL,
    url: url,
    method: 'post',
    data: JSON.stringify(data),
    headers: headers,
  }).then((res) => res.data);
};
