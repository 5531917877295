<template>
  <div>
    <van-nav-bar
      :title="title"
      :left-text="title !== '广告系列'?'返回':''"
      :left-arrow="title !== '广告系列' "
      @click-left="onClickLeft"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
        title:'广告管理'
    };
  },
  computed: {
    // title: {
    //   get() {
    //     return this.$router.history.current.meta.title;
    //   },
    // },
  },
  watch: {
    $route: {
      handler(val, oldval) {
          this.title = val.meta.title;
      },
      // 深度观察监听
      deep: true,
    },
  },
  methods: {
    onClickLeft() {
      console.log(this.$router);
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>