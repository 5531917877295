import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import Cookies from 'js-cookie';
import { profile, dingAuthCode, check } from '@/api/user';
import * as dd from 'dingtalk-jsapi';
import { addMark } from '@/utils/watermark';
// 钉钉登录
export function ddLogin(to, next) {
  dd.ready(() => {
    getDdAuthCode(
      (info) => {
        let code = info.code;
        dingAuthCode(code)
          .then(async (response) => {
            console.log('dingAuthCode1', response);
            if (response.code == 0) {
              Cookies.set('token', response.data.token);
              Cookies.set('adcose_uid', response.data.id);
              Cookies.set('adcose_uid_time', new Date().getTime());
              try {
                let resp = await profile();
                store.commit('user/SETUSERINFO', resp);
                localStorage.setItem('userInfo', JSON.stringify(resp.data));
              } catch (error) {
                alert(JSON.stringify(error));
              }
              next();
            } else if (response.code == 11) {
              const list = response.data.map((v) => v.account);
              dd.device.notification.actionSheet({
                title: '请选择单点账号', //标题
                cancelButton: '取消', //取消按钮文本
                otherButtons: list,
                onSuccess: function (result) {
                  let buttonIndex = result.buttonIndex;
                  if (buttonIndex == -1) {
                    alert('已取消登录');
                    next(false);
                  } else {
                    let userId = response.data[buttonIndex].id;
                    getDdAuthCode(
                      (inf) => {
                        let cod = inf.code;
                        dingAuthCode(cod, userId)
                          .then(async (respons) => {
                            console.log('dingAuthCode2', respons);
                            if (respons.code == 0) {
                              Cookies.set('token', respons.data.token);
                              Cookies.set('adcose_uid', respons.data.id);
                              Cookies.set('adcose_uid_time', new Date().getTime());
                              try {
                                let resp = await profile();
                                store.commit('user/SETUSERINFO', resp);
                                localStorage.setItem('userInfo', JSON.stringify(resp.data));
                              } catch (error) {
                                alert(JSON.stringify(error));
                              }
                              next();
                            } else {
                              alert('接口code报错：' + JSON.stringify(respons));
                              next(false);
                            }
                          })
                          .catch((err) => {
                            next(false);
                            alert('接口报错：' + JSON.stringify(err));
                          });
                      },
                      (err) => {
                        next(false);
                      },
                    );
                  }
                },
                onFail: function (err) {},
              });
            } else {
              alert('接口code报错：' + JSON.stringify(response));
              next(false);
            }
          })
          .catch((err) => {
            next(false);
            alert('接口报错：' + JSON.stringify(err));
          });
      },
      (err) => {
        next(false);
      },
    );
  });
}

function getDdAuthCode(success, fail) {
  dd.runtime.permission.requestAuthCode({
    corpId: 'ding795387c883ef25ff35c2f4657eb6378f',
    onSuccess: (info) => {
      success && success(info);
    },
    onFail: (err) => {
      console.log(err);
      fail && fail(err);
      alert('钉钉报错：' + JSON.stringify(err));
    },
  });
}
router.beforeEach(async (to, from, next) => {
  let isLogin = store.state.user.userInfo.data?.logined ?? false;
  if (!isLogin) {
    //防止登录后路由切换时再次触发初次登录的相关动作
    let res = await profile();
    localStorage.setItem('userInfo', JSON.stringify(res.data));
    addMark();
    store.commit('user/SETUSERINFO', res);
    if (res.code == 0) {
      // console.log(res);
      if (!res.data.logined) {
        if (dd.env.platform != 'notInDingTalk') {
          ddLogin(to, next);
        } else {
          // alert('当前非钉钉环境，请用钉钉打开')
          const link = process.env.VUE_APP_BASE_HREF;
          location.href = `/adreport/login/check?_redirect_page=${link}/campaign`;
          check({ _redirect_page: `${link}/campaign` });
          // next()
        }
      } else {
        next();
      }
    } else {
      next(false);
    }
  } else {
    next();
  }
});
// // 有个号无账号
// router.beforeResolve(async (to, from, next) => {
//   let isLogin = store.state.user.userInfo.data?.logined ?? false
//   if (!isLogin) { //防止登录后路由切换时再次触发初次登录的相关动作
//     if (dd.env.platform != 'notInDingTalk') {
//       ddLogin(to, next)
//     } else {
//       alert('请用钉钉打开')
//     }
//   } else {
//     next()
//   }
// })
