import Vue from 'vue'
import { Toast  } from 'vant'

let loadingCount = 0
let loading

const startLoading = (e) => {
    loading = Toast.loading({
        duration: 0,
        message: e?.message ?? '加载中……',
        forbidClick: true,
    })
}

const endLoading = () => {
    loading.clear()
}


const showLoading = (e) => {
    if (loadingCount === 0) {
        startLoading(e)
    }
    loadingCount += 1
}

const hideLoading = () => {
    if (loadingCount <= 0) {
        return
    }
    loadingCount -= 1
    if (loadingCount === 0) {
        endLoading()
    }
}

Vue.prototype.$showLoading = showLoading
Vue.prototype.$hideLoading = hideLoading
