// const path = require("path");
module.exports = {
    publicPath: './',
    outputDir: 'dist', //build输出目录
    assetsDir: 'assets', //静态资源目录（js, css, img）
    lintOnSave: true, //是否开启eslint
    devServer: {
        open: true, //是否自动弹出浏览器页面
        host: "localhost",
        port: '8090',
        https: false, //是否使用https协议
        hotOnly: false, //是否开启热更新
        proxy: {
            '/adreport': { // 测试环境
                target: 'http://192.168.4.158:8090',//'http://192.168.1.64:8090',
                changeOrigin: true,
                logLevel: "debug",
                // pathRewrite: {
                //     '^/api': ''
                // }
            },
            '/mobile':{
                target: 'http://192.168.1.115:8082',
                changeOrigin: true,
                logLevel: "debug",
            }
            // 明文电脑
            // '/adreport': { // 测试环境
            //     target: 'http://192.168.1.141:8080',
            //     changeOrigin: true,
            //     logLevel: "debug",
            //     // pathRewrite: {
            //         // '^/adreport': ''
            //     // }
            // },
        }
    },
    css: {
        loaderOptions: {
            postcss: {
                plugins: [
                    require('postcss-pxtorem')({
                        rootValue: 37.5, // 换算的基数
                        selectorBlackList:[".van"],
                        propList: ['*'],
                    }),
                ]
            },
            less: {
                // 若 less-loader 版本小于 6.0，请移除 lessOptions 这一级，直接配置选项。
                modifyVars: {
                  // 或者可以通过 less 文件覆盖（文件路径为绝对路径）
                //   'hack': `true; @import "${path.join(__dirname,"/src/styles/myVant.less")}`
                }
            },
        }
    },
    chainWebpack: config => {
        config.plugin('html')
            .tap(args => {
                args[0].title = "广告投放手机端";
                return args;
            })
    }
}