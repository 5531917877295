<template>
  <div id="app">
    <div class="mobileCon">
      <!-- <nav-bar></nav-bar> -->
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive">
          <!-- 这里是会被缓存的视图组件，比如 Home！ -->
        </router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive">
        <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
      </router-view>
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
export default {
  name: "App",
  components: {
    navBar,
  },
};
</script>

<style lang="scss">
#app {
  .mobileCon {
    // padding-top: 80px;
  }
}
</style>
